<template>
    <public-frame-dark :menu="false">
        <div class="page-body">
            <div class="ctr body-container h-100 py-2">
                <div class="flex-in-middle h-100">
                    <div class="error-404 flex-in-middle">
                        <div class="text-center"><h1 class="text-danger">{{ $t("MESSAGE.NOT-FOUND") }}</h1>
                            <br/>
                            <h3><a href="javascript://void(0);" @click="$router.push('/portal')"
                                   style="cursor: pointer; text-decoration: none;color: black">{{ $t('TITLE.GO-BACK') }}</a>
                            </h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </public-frame-dark>
</template>

<script>
import PublicFrameDark from "@/components/public-frame-dark";

export default {
    name: "notfound",
    components: {PublicFrameDark}
}
</script>

<style scoped>

</style>
