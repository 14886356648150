<template>
    <public-frame-dark :menu="false">
        <div class="flex-in-middle page-body">
            <div class="text-center">
                <h1 style="font-size: 4rem;">403 {{$t("ERROR.UNAUTHORIZED")}}</h1>
                <br />
                <h3><a href="javascript://void(0);" @click="$router.push('/portal')" style="cursor: pointer; text-decoration: none;color: black">{{$t('TITLE.GO-BACK')}}</a></h3>
            </div>
        </div>
    </public-frame-dark>
</template>
<script>
import PublicFrameDark from "@/components/public-frame-dark";

export default {
    name: "prohibit",
    components: {PublicFrameDark},
    methods: {
        goBack() {
            this.$router.back();
        }
    }
}
</script>
