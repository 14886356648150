<template>
    <public-frame>
        <router-view :key="pageKey"></router-view>
    </public-frame>
</template>
<script>

import PublicFrame from "@/components/public-frame-dark"

export default {
    name: "public",
    components:{PublicFrame},
    computed:{
        pageKey() {
            if (this.$route.meta) {
                if (this.$route.meta.model) return this.$route.meta.model;
                else return this.$route.name;
            }
            return '';
        }
    }
}
</script>
